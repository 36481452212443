import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Modal, Button, Form } from "react-bootstrap";

import Resources from "../lib/resources";
import AccountPaymentMethods from "./accountPaymentMethods";
import SwitchButton from "./controls/switchButton";
import MainLoader from "./library/mainLoader";

import { dispatchToProps as uaDP } from "../store/user-actions";
import { dispatchToProps as aDP } from "../store/accounts-actions";
import { dispatchToProps as pmDP } from "../store/paymentMethods-actions";
import QueryString from "qs";

const dispatchToProps = dispatch => ({
  ...uaDP(dispatch),
  ...aDP(dispatch),
  ...pmDP(dispatch)
});

class PaymentMethods extends Component {
  constructor(props, context) {
    super(props, context);

    this.renderEditPaymentMethodModal = this.renderEditPaymentMethodModal.bind(this);
    this.editPaymentMethod = this.editPaymentMethod.bind(this);
    this.savePaymentMethod = this.savePaymentMethod.bind(this);
    this.handleClosePaymentMethod = this.handleClosePaymentMethod.bind(this);
    this.toggleActiveStatus = this.toggleActiveStatus.bind(this);
  
    this.state = {
      activePaymentMethod: !window.localStorage.getItem('activePaymentMethod') ? {} : JSON.parse(window.localStorage.getItem('activePaymentMethod')),
      showAccount: !window.localStorage.getItem('showAccount') ? null : JSON.parse(window.localStorage.getItem('showAccount')),
      affinipay: (window.location + "").split('?', 2)[1] === undefined ? null : JSON.parse(QueryString.parse((window.location + "").split('?', 2)[1]).affinipayResponse)
    };
  }

  componentDidMount() {
    this.ensureAccounts(true);
    if (this.state.showAccount != null) {
      this.setAccount(this.state.showAccount);
      if (this.state.activePaymentMethod != null) {
        this.editPaymentMethod(this.state.activePaymentMethod);
      }
    }
  }

  componentDidUpdate() {
    this.ensureAccounts(false);
  }

  ensureAccounts(force) {
    if (
      force === true ||
      (this.props.accounts.fetchingAccounts === false &&
        this.props.accounts.fetchedAccounts !== true &&
        this.props.accounts.fetchAccountsFailed !== true)
    ) {
      this.props.fetchAccounts();
    }
  }

  setAccount(accountId) {
    this.props.fetchPaymentMethods(accountId);
    this.setState({ showAccount: accountId });
  }

  handleClosePaymentMethod() {
    this.setState({ isEditingPaymentMethod: false, affinipay: null });
    window.localStorage.removeItem('activePaymentMethod');
    window.localStorage.removeItem('showAccount');
  }

  editPaymentMethod(paymentMethod) {
    this.setState({ isEditingPaymentMethod: true, activePaymentMethod: paymentMethod });
  }

  savePaymentMethod() {
    this.props.updatePaymentMethod(this.state.showAccount, this.state.activePaymentMethod);
    this.setState({ isEditingPaymentMethod: false, affinipay: null });
    window.localStorage.removeItem('activePaymentMethod');
    window.localStorage.removeItem('showAccount');
  }

  toggleActiveStatus(paymentMethod) {
    paymentMethod.isActive = !paymentMethod.isActive;
    this.props.updatePaymentMethod(this.state.showAccount, paymentMethod);
  }

  renderEditPaymentMethodModal() {
    return <Modal show={this.state.isEditingPaymentMethod} onHide={this.handleClosePaymentMethod}>
      <Modal.Header closeButton>
        <Modal.Title>{this.state.activePaymentMethod.paymentMethodId === undefined ? "New" : "Edit"} Payment Method</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {(() => {
          if (!this.state.affinipay && this.state.activePaymentMethod.beginOAuthIntegration === true && this.state.activePaymentMethod.paymentProviderName == "Affinipay") {
            if (this.props.paymentMethods.oAuthIntegrationLoading === true) {
              return (<MainLoader/>);
            } else {
              window.location = this.props.paymentMethods.oAuthIntegrationUrl;
            }
          } else {
            return (<>
              <Form.Group>
                <Form.Label>Payment Method Name</Form.Label>
                <Form.Control
                  value={this.state.activePaymentMethod.identifier}
                  onChange={(e) => {this.setState({ activePaymentMethod: { ...this.state.activePaymentMethod, identifier: e.target.value } })}}
                  placeholder="SimpleMethodNameNoSpaces"/>
              </Form.Group>
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Control
                  value={this.state.activePaymentMethod.description}
                  onChange={(e) => {this.setState({ activePaymentMethod: { ...this.state.activePaymentMethod, description: e.target.value } })}}
                  placeholder="Description"/>
              </Form.Group>
              <Form.Group>
                <Form.Label>Company ID</Form.Label>
                <Form.Control
                  value={this.state.activePaymentMethod.companyId}
                  onChange={(e) => {this.setState({ activePaymentMethod: { ...this.state.activePaymentMethod, companyId: e.target.value } })}}
                  placeholder="Company_ID"/>
              </Form.Group>
              <Form.Group>
                <Form.Label>Cust Class ID</Form.Label>
                <Form.Control
                  value={this.state.activePaymentMethod.custClassId || ""}
                  onChange={(e) => {this.setState({ activePaymentMethod: { ...this.state.activePaymentMethod, custClassId: e.target.value } })}}
                  placeholder=""/>
              </Form.Group>
              <Form.Group>
                <Form.Label>Currency ID</Form.Label>
                <Form.Control
                  value={this.state.activePaymentMethod.currencyId}
                  onChange={(e) => {this.setState({ activePaymentMethod: { ...this.state.activePaymentMethod, currencyId: e.target.value } })}}
                  placeholder=""/>
              </Form.Group>
              <Form.Group>
                <Form.Label>Maximum Payment Amount</Form.Label>
                <Form.Control
                  value={this.state.activePaymentMethod.maxPaymentAmount}
                  onChange={(e) => {this.setState({ activePaymentMethod: { ...this.state.activePaymentMethod, maxPaymentAmount: e.target.value } })}}
                  placeholder=""/>
              </Form.Group>
              <Form.Group>
                <Form.Label>Provider</Form.Label>
                <Form.Control
                  as="select"
                  value={this.state.activePaymentMethod.paymentProviderName}
                  onChange={(e) => {this.setState({ activePaymentMethod: { ...this.state.activePaymentMethod, paymentProviderName: e.target.value } })}}>
                  <option>Affinipay</option>
                  <option>SoluPay</option>
                  <option>SagePayment</option>
                  <option>APS</option>
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Base Url</Form.Label>
                <Form.Control
                  value={this.state.activePaymentMethod.paymentProviderBaseUrl}
                  onChange={(e) => {this.setState({ activePaymentMethod: { ...this.state.activePaymentMethod, paymentProviderBaseUrl: e.target.value } })}}
                  placeholder=""/>
              </Form.Group>
              <Form.Group>
                <Form.Label>Secondary Url</Form.Label>
                <Form.Control
                  value={this.state.activePaymentMethod.paymentProviderSecondaryUrl}
                  onChange={(e) => {this.setState({ activePaymentMethod: { ...this.state.activePaymentMethod, paymentProviderSecondaryUrl: e.target.value } })}}
                  placeholder=""/>
              </Form.Group>
              <Form.Group>
                <Form.Label>Merchant App Id</Form.Label>
                <Form.Control
                  value={this.state.activePaymentMethod.merchantAppId}
                  onChange={(e) => {this.setState({ activePaymentMethod: { ...this.state.activePaymentMethod, merchantAppId: e.target.value } })}}
                  placeholder=""/>
              </Form.Group>
              {(() => {
                if (this.state.activePaymentMethod.paymentProviderName == "Affinipay") {
                  if (this.state.affinipay == null) {
                    return (<Form.Group>
                      <Form.Label>Connect to AffiniPay</Form.Label><br/>
                      <Button variant="link" onClick={(e) => {
                        this.setState({ activePaymentMethod: { ...this.state.activePaymentMethod, beginOAuthIntegration: true } });
                        window.localStorage.setItem("activePaymentMethod", JSON.stringify(this.state.activePaymentMethod));
                        window.localStorage.setItem("showAccount", JSON.stringify(this.state.showAccount));
                        this.props.beginOAuthIntegration(this.state.activePaymentMethod.paymentProviderName);
                      }}>
                        <img src="/img/AffiniPay-Logo.png" alt="AffiniPay" height="50" width="50"/> Connect to AffiniPay
                      </Button>
                    </Form.Group>);
                  } else {
                    return (<>
                      <Form.Group>
                        <Form.Label>Account Selection</Form.Label>
                        <Form.Control
                          as="select"
                          value={this.state.activePaymentMethod.merchantLoginId}
                          onChange={(e) => {
                            this.setState({
                              activePaymentMethod: { 
                                ...this.state.activePaymentMethod,
                                merchantLoginId: e.target.value,
                                merchantLoginKey: this.state.affinipay.test_accounts.concat(this.state.affinipay.live_accounts)
                                  .filter(a => a.id === e.target.value)
                                  .map(a => a.public_key + ":" + a.secret_key)[0]
                              }})}}>
                          {this.state.affinipay.test_accounts.concat(this.state.affinipay.live_accounts).map(a => {
                            return <option key={a.id} value={a.id}>{a.name}</option>
                          })}
                        </Form.Control>
                      </Form.Group>
                    </>);
                  }
                } else {
                  return (<>
                    <Form.Group>
                      <Form.Label>{this.state.activePaymentMethod.paymentProviderName == "APS" ? "API Key" : "Merchant Login Id"}</Form.Label>
                      <Form.Control
                        value={this.state.activePaymentMethod.merchantLoginId}
                        onChange={(e) => {this.setState({ activePaymentMethod: { ...this.state.activePaymentMethod, merchantLoginId: e.target.value } })}}
                        placeholder=""/>
                    </Form.Group>
                    {(() => {
                      if (this.state.activePaymentMethod.paymentProviderName == "APS") {
                        return <>
                          <Form.Group>
                            <Form.Label>Summary Commodity Code</Form.Label>
                            <Form.Control
                              value={((this.state.activePaymentMethod.merchantLoginKey || "").split(':') || [""])[0]}
                              onChange={(e) => {this.setState({ activePaymentMethod: { ...this.state.activePaymentMethod, merchantLoginKey: e.target.value + ":" + (((this.state.activePaymentMethod.merchantLoginKey || "").split(':') || ["", ""])[1] || "") } })}}
                              placeholder=""/>
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Item Commodity Code</Form.Label>
                            <Form.Control
                              value={((this.state.activePaymentMethod.merchantLoginKey || "").split(':') || ["", ""])[1] || ""}
                              onChange={(e) => {this.setState({ activePaymentMethod: { ...this.state.activePaymentMethod, merchantLoginKey: ((this.state.activePaymentMethod.merchantLoginKey || "").split(':') || [""])[0] + ":" + e.target.value } })}}
                              placeholder=""/>
                          </Form.Group>
                        </>;
                      } else {
                        return <Form.Group>
                          <Form.Label>Merchant Login Key</Form.Label>
                          <Form.Control
                            value={this.state.activePaymentMethod.merchantLoginKey}
                            onChange={(e) => {this.setState({ activePaymentMethod: { ...this.state.activePaymentMethod, merchantLoginKey: e.target.value } })}}
                            placeholder=""/>
                        </Form.Group>;
                      }
                    })()}
                  </>);
                }
              })()}
            </>);
          }
        })()}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={this.handleClosePaymentMethod}>
          Close
        </Button>
        <Button variant="primary" onClick={this.savePaymentMethod}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>;
  }

  render() {
    return (
      <div>
        <div className="panel-title">
          <h3>{Resources.PaymentMethods}</h3>
        </div>
        {this.renderEditPaymentMethodModal()}
        <div className="customers-panel">
          <div className="customers-panel-title">Accounts</div>
          {(this.props.accounts || { accounts: [] }).accounts.filter(h => h.accountType == "ATC").map(h => {
            if (this.state.showAccount && this.state.showAccount === h.accountId) {
              return (
                <div className="customers-panel-expanded" key={h.accountId}>
                  <AccountPaymentMethods
                    account={h}
                    toggleText="Hide"
                    toggle={() => this.setState({ showAccount: null })}
                    addPaymentMethod={() => this.editPaymentMethod({})}
                  />
                  <div className="customers-panel-expanded-rows">
                  {(this.props.paymentMethods || { paymentMethods: [] }).paymentMethods.map(i => {
                    return (
                      <Row className="customers-panel-expanded-row" key={i.merchantAccountId} noGutters>
                        <Col xs={12} sm={3}>
                          <div className="customers-panel-heading">Payment Configuration</div>
                          <div className="customers-panel-value">
                            {i.identifier} ({i.description})
                          </div>
                        </Col>
                        <Col xs={0} sm={2}>
                          <div className="customers-panel-heading">Company</div>
                          <div className="customers-panel-value">
                            {i.companyId}
                          </div>
                        </Col>
                        <Col xs={6} sm={2}>
                          <div className="customers-panel-heading">Customer Class</div>
                          <div className="customers-panel-value">
                            {i.custClassId}
                          </div>
                        </Col>
                        <Col xs={6} sm={1}>
                          <div className="customers-panel-heading">Currency</div>
                          <div className="customers-panel-value">
                            {i.currencyId}
                          </div>
                        </Col>
                        <Col xs={3} sm={1}>
                          <div className="customers-panel-heading">Max Amt</div>
                          <div className="customers-panel-value customers-panel-value-mono">
                            {i.maxPaymentAmount}
                          </div>
                        </Col>
                        <Col xs={3} sm={1}>
                          <div className="customers-panel-heading">Provider</div>
                          <div className="customers-panel-value">
                            {i.paymentProviderName}
                          </div>
                        </Col>
                        <Col xs={3} sm={1}>
                          <div className="customers-panel-heading">Active</div>
                          <div className="customers-panel-value customers-panel-actions">
                            <SwitchButton
                              value={i.isActive}
                              onClick={() => this.toggleActiveStatus(i)}
                            />
                          </div>
                        </Col>
                        <Col xs={3} sm={1}>
                          <div className="customers-panel-heading customers-panel-actions">Actions</div>
                          <div className="customers-panel-value customers-panel-actions">
                            <span className="fas fa-edit" onClick={() => this.editPaymentMethod(i)}/>
                            <span className="fas fa-trash"/>
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
                </div>
              </div>
              );
            } else {
              return (
                <div className="customers-panel-collapsed" key={h.accountId}>
                  <AccountPaymentMethods
                    account={h}
                    toggleText="Show Payment Methods"
                    toggle={() => this.setAccount(h.accountId)}
                  />
                </div>
              );
            }
          })}
        </div>
      </div>
    );
  }
}

const storeToProps = store => {
  return {
    user: store.user,
    accounts: store.accounts,
    paymentMethods: store.paymentMethods
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(PaymentMethods);
