import { getString, formatString } from "./strings";

class Resources {
  /// CRUD
  get Add() {
    return getString("ADD", "Add");
  }
  get Edit() {
    return getString("EDIT", "Edit");
  }
  get Delete() {
    return getString("DELETE", "Delete");
  }

  /// Action\Responses
  get Ok() {
    return getString("OK", "Ok");
  }
  get Cancel() {
    return getString("CANCEL", "Cancel");
  }
  get Close() {
    return getString("CLOSE", "Close");
  }

  get Yes() {
    return getString("YES", "Yes");
  }
  get No() {
    return getString("NO", "No");
  }

  get Save() {
    return getString("SAVE", "Save");
  }
  get Submit() {
    return getString("SUBMIT", "Submit");
  }
  get Send() {
    return getString("SEND", "Send");
  }

  get Preview() {
    return getString("PREVIEW", "Preview");
  }
  get View() {
    return getString("VIEW", "View");
  }

  get Select() {
    return getString("SELECT", "Select");
  }
  get Clear() {
    return getString("CLEAR", "Clear");
  }

  get Upload() {
    return getString("UPLOAD", "Upload");
  }
  get Search() {
    return getString("SEARCH", "Search");
  }
  get Resend() {
    return getString("RESEND", "Resend");
  }
  get Resending() {
    return getString("RESENDING", "Resending");
  }
  get Resent() {
    return getString("RESENT", "Resent");
  }
  get Retry() {
    return getString("RETRY", "Retry");
  }
  get Refresh() {
    return getString("REFRESH", "Refresh");
  }

  get Hide() {
    return getString("HIDE", "Hide");
  }
  get Show() {
    return getString("SHOW", "Show");
  }

  /// areas
  get Reports() {
    return getString("REPORTS", "Reports");
  }
  get Manage() {
    return getString("MANAGE", "Manage");
  }
  get Accounts() {
    return getString("ACCOUNTS", "Accounts");
  }
  get Company() {
    return getString("COMPANY", "Company");
  }

  get Pending() {
    return getString("PENDING", "Pending");
  }
  get Accepted() {
    return getString("ACCEPTED", "Accepted");
  }
  get Refreshing() {
    return getString("REFRESHING", "Refreshing");
  }

  get Dates() {
    return getString("DATES", "Dates");
  }
  get Property() {
    return getString("PROPERTY", "Property");
  }
  get Value() {
    return getString("VALUE", "Value");
  }
  get All() {
    return getString("ALL", "All");
  }

  get Help() {
    return getString("HELP", "Help");
  }
  get SignIn() {
    return getString("SIGN_IN", "Sign In");
  }
  get SigningIn() {
    return getString("SIGNING_IN", "Signing In");
  }
  get Logout() {
    return getString("LOGOUT", "Logout");
  }
  get Settings() {
    return getString("SETTINGS", "Settings");
  }
  get Home() {
    return getString("HOME", "Home");
  }
  get HomeTitle() {
    return getString("HOME_TITLE", "Welcome to Lockstep!");
  }
  get LoginTitle() {
    return getString("LOGIN_TITLE", "Login");
  }
  get Login() {
    return getString("LOGIN", "Login");
  }

  get UnknownSources() {
    return getString("UKNOWN_SOURCES", "Unknown Sources");
  }
  get SortBy() {
    return getString("SORT_BY", "Sort By");
  }
  get Name() {
    return getString("NAME", "Name");
  }
  get MostRecent() {
    return getString("MOST_RECENT", "Most Recent");
  }
  get Filter() {
    return getString("FILTER", "Filter");
  }

  get UserLoginFailure() {
    return getString("USER_LOGIN_FAILURE", "Login invalid, Please try again.");
  }

  get AccountInformation() {
    return getString("ACCOUNT_INFORMATION", "Account Information");
  }

  get CompanyInformation() {
    return getString("COMPANY_INFORMATION", "Company Information");
  }
  get CompanyName() {
    return getString("COMPANY_NAME", "Company Name");
  }

  /// invite company
  get FirstName() {
    return getString("FIRST_NAME", "First Name:");
  }
  get LastName() {
    return getString("LAST_NAME", "Last Name:");
  }
  get Email() {
    return getString("EMAIL", "E-mail:");
  }

  get EmailInvitationHeader() {
    return getString("EMAIL_INVITATION_HEADER", "E-mail Invitation");
  }
  get Message() {
    return getString("MESSAGE", "Message");
  }

  get APEmail() {
    return getString("AP_EMAIL", "AP E-mail:");
  }
  get AREmail() {
    return getString("AR_EMAIL", "AR E-mail:");
  }
  get APPhone() {
    return getString("AP_PHONE", "AP Phone:");
  }
  get ARPhone() {
    return getString("AR_PHONE", "AR Phone:");
  }
  get EntityType() {
    return getString("ENTITY_TYPE", "Entity Type:");
  }

  get CompanyInfoAddress() {
    return getString("COMPANY_INFO_ADDRESS", "Address:");
  }
  get CompanyInfoCity() {
    return getString("COMPANY_INFO_CITY", "City:");
  }
  get CompanyInfoState() {
    return getString("COMPANY_INFO_STATE", "State/Province:");
  }
  get CompanyInfoZip() {
    return getString("COMPANY_INFO_ZIP", "Postal Code:");
  }
  get CompanyInfoCountry() {
    return getString("COMPANY_INFO_COUNTRY", "Country:");
  }
  get CompanyInfoUrl() {
    return getString("COMPANY_INFO_URL", "URL:");
  }
  get CompanyInfoTIN() {
    return getString("COMPANY_INFO_TIN", "TIN:");
  }
  get CompanyInfoW9() {
    return getString("COMPANY_INFO_W9", "W9 File:");
  }
  get CompanyInfoW8() {
    return getString("COMPANY_INFO_W8", "W8 BEN File:");
  }

  get EmailRe() {
    return getString("EMAIL_RE", "RE:");
  }
  get EmailFw() {
    return getString("EMAIL_FW", "FW:");
  }

  get Lockstep() {
    return getString("LOCKSTEP", "Lockstep");
  }

  get Previous() {
    return getString("PREVIOUS", "Previous");
  }
  get Next() {
    return getString("NEXT", "Next");
  }

  get IconSpinner() {
    return getString("ICON_SPINNER", "fas fa-spinner");
  }

  get Subject() {
    return getString("SUBJECT", "Subject");
  }
  get NoSubject() {
    return getString("NO_SUBJECT", "(No subject)");
  }

  get Date() {
    return getString("DATE", "Date");
  }
  get Status() {
    return getString("STATUS", "Status");
  }
  get Action() {
    return getString("ACTION", "Action");
  }

  get Password() {
    return getString("PASSWORD", "Password");
  }

  get HealthDashboard() {
    return getString("HEALTH_DASHBOARD", "Health Dashboard");
  }
  get InviteCompany() {
    return getString("INVITE_COMPANY", "Invite Company");
  }
  get InvitationHistory() {
    return getString("INVITATION_HISTORY", "Invitation History");
  }
  get DefaultInvitationSubject() {
    return getString("DEFAULT_INVITATION_SUBJECT", "Early Access Invitation from Lockstep ");
  }
  get DefaultInvitationMessage() {
    return getString(
      "DEFAULT_INVITATION_MESSAGE",
      `Are your AP and AR teams at <b>[company name]</b> ready to spend less time in email?  If so, accept this private invitation to use Lockstep.  Lockstep integrates your central email inbox to your accounting system to improve workflow, automate responses, and access information effortlessly.  Lockstep will save time every day for your team.  Get started now.`
    );
  }
  InvitationSentConfirmation(companyName, emailDomain) {
    return formatString(
      getString("INVITATION_SENT_CONFIRMATION", "E-mail invitation sent to {companyName} from help@{emailDomain}"),
      { companyName, emailDomain }
    );
  }

  get AccountsPayable() {
    return getString("ACCOUNTS_PAYABLE", "Accounts Payable");
  }
  get AccountsReceivable() {
    return getString("ACCOUNTS_RECEIVABLE", "Accounts Receivable");
  }
  get DefaultPerspective() {
    return getString("DEFAULT_PERSPECTIVE", "Perspective:");
  }

  get ManageUsers() {
    return getString("MANAGE_USERS", "Manage Users");
  }
  get Username() {
    return getString("USERNAME", "Username");
  }
  get LoginAs() {
    return getString("LOGIN_AS", "Login As");
  }

  get Statistics() {
    return getString("STATISTICS", "Statistics");
  }
  get FeatureGroups() {
    return getString("FEATUREGROUPS", "Feature Groups");
  }
  get Features() {
    return getString("FEATURES", "Features");
  }
  get SubscriptionTypes() {
    return getString("SUBSCRIPTIONTYPES", "Subscription Types");
  }

  get BulkSenders() {
    return getString("BULK_SENDERS", "Bulk Senders");
  }
  get ServiceEmailTemplates() {
    return getString("SERVICE_EMAIL_TEMPLATES", "Service Email Templates");
  }
  get ConnectorManagement() {
    return getString("CONNECTOR_MANAGEMENT", "Connector Management");
  }

  get PaymentMethods() {
    return getString("PAYMENT_METHODS", "Payment Methods");
  }

  get Internationalization() {
    return getString("INTERNATIONALIZATION", "Internationalization");
  }

  get UnprocessedEntities() {
    return getString("UNPROCESSED_ENTITIES", "Unprocessed Entities");
  }

  get AccountKey() {
    return getString("ACCOUNT_KEY", "Account Key");
  }

  get EntityName() {
    return getString("ENTITY_NAME", "Entity name");
  }

  get TableName() {
    return getString("TABLE_NAME", "Table name");
  }

  get LastUpdated() {
    return getString("LAST_UPDATED", "Last updated");
  }

  get DateInserted() {
    return getString("DATE_INSERTED", "Date inserted");
  }

  get EntityKeyInt() {
    return getString("ENTITY_KEY_INT", "Entity key int");
  }

  get SignupControlPanel() {
    return getString("NEW_USER_CONTROL_PANEL", "Signup Control Panel");
  }

  get SignupControl() {
    return getString("CONTROL_PANEL", "Signup Control");
  }

  get Enabled() {
    return getString("ENABLED", "Enabled");
  }

  get Disabled() {
    return getString("DISABLED", "Disabled");
  }

  get Claim() {
    return getString("CLAIM", "Claim");
  }

  get All() {
    return getString("ALL", "All");
  }

  get Create() {
    return getString("CREATE", "Create");
  }

  get Internationalization() {
    return getString("INTERNATIONALIZATION", "Internationalization");
  }

  get Migration() {
    return getString("MIGRATION", "Migration");
  }

  get ScriptManagement() {
    return getString("SCRIPT_MANAGEMENT", "Script Management");
  }

  getString(name) {
    return getString(name.toUpperCase(), `[${name}]`);
  }
}

const resources = new Resources();

export default resources;
