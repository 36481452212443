import data from "../lib/data";
import { actions as GeneralActions } from './general-actions'
import { handlePromiseError } from './error-actions'

export const actions = {
  ...GeneralActions, ...{

    FETCHING_PAYMENT_METHODS: "FETCHING_PAYMENT_METHODS",
    FETCHED_PAYMENT_METHODS: "FETCHED_PAYMENT_METHODS",
    FETCH_PAYMENT_METHODS_FAILED: "FETCH_PAYMENT_METHODS_FAILED",

    UPDATING_PAYMENT_METHODS: "UPDATING_PAYMENT_METHODS",
    UPDATED_PAYMENT_METHODS: "UPDATED_PAYMENT_METHODS",
    UPDATE_PAYMENT_METHODS_FAILED: "UPDATE_PAYMENT_METHODS_FAILED",

    BEGINNING_OAUTH_INTEGRATION: "BEGINNING_OAUTH_INTEGRATION",
    BEGAN_OAUTH_INTEGRATION: "BEGAN_OAUTH_INTEGRATION",
    BEGINNING_OAUTH_INTEGRATION_FAILED: "BEGINNING_OAUTH_INTEGRATION_FAILED",

    FETCHING_OAUTH_INFORMATION: "FETCHING_OAUTH_INFORMATION",
    FETCHED_OAUTH_INFORMATION: "FETCHED_OAUTH_INFORMATION",
    FETCH_OAUTH_INFORMATION_FAILED: "FETCH_OAUTH_INFORMATION_FAILED"
  }

};

const fetchPaymentMethods = (companyId) => ((dispatch, getState) => {
    const state = getState();
    dispatch({ type: actions.FETCHING_PAYMENT_METHODS });
    data.get(`v2/api/payments/providers/${companyId}`).then((response) => {
        dispatch({ type: actions.FETCHED_PAYMENT_METHODS, paymentMethods: response.data });
    }).catch((rejection) => {
        dispatch({ type: actions.FETCH_PAYMENT_METHODS_FAILED, rejection });
        handlePromiseError(rejection);
    });
});

const updatePaymentMethod = (companyId, activePaymentMethod) => ((dispatch, getState) => {
    const state = getState();
    dispatch({ type: actions.UPDATING_PAYMENT_METHODS, activePaymentMethod });
    data.post(`v2/api/payments/providers/${companyId}`, {
        ...activePaymentMethod
    }).then((response) => {
        dispatch({ type: actions.UPDATED_PAYMENT_METHODS, paymentMethods: response.data, activePaymentMethod: {} });
    }).catch((rejection) => {
        dispatch({ type: actions.UPDATE_PAYMENT_METHODS_FAILED, rejection });
        handlePromiseError(rejection);
    });
});

const createPaymentMethod = (companyId, activePaymentMethod) => ((dispatch, getState) => {
    const state = getState();
    dispatch({ type: actions.UPDATING_PAYMENT_METHODS, activePaymentMethod });
    data.post(`v2/api/payments/providers/${companyId}`, {
        ...activePaymentMethod
    }).then((response) => {
        dispatch({ type: actions.UPDATED_PAYMENT_METHODS, paymentMethods: response.data, activePaymentMethod: {} });
    }).catch((rejection) => {
        dispatch({ type: actions.UPDATE_PAYMENT_METHODS_FAILED, rejection });
        handlePromiseError(rejection);
    });
});

const deletePaymentMethod = (companyId, merchantAccountId) => ((dispatch, getState) => {
    const state = getState();
    dispatch({ type: actions.UPDATING_PAYMENT_METHODS });
    data.delete(`v2/api/payments/providers/${companyId}/${merchantAccountId}`).then((response) => {
        dispatch({ type: actions.UPDATED_PAYMENT_METHODS, paymentMethods: response.data, activePaymentMethod: {} });
    }).catch((rejection) => {
        dispatch({ type: actions.UPDATE_PAYMENT_METHODS_FAILED, rejection });
        handlePromiseError(rejection);
    });
});

const beginOAuthIntegration = (provider) => ((dispatch, getState) => {
    const state = getState();
    dispatch({ type: actions.BEGINNING_OAUTH_INTEGRATION, oAuthIntegrationLoading: true });
    data.get(`v2/api/payments/providers/${provider}/oauth/authorize`).then((response) => {
        dispatch({ type: actions.BEGAN_OAUTH_INTEGRATION, oAuthIntegrationLoading: false, oAuthIntegrationUrl: response.data });
    }).catch((rejection) => {
        dispatch({ type: actions.BEGINNING_OAUTH_INTEGRATION_FAILED, oAuthIntegrationLoading: false, oAuthIntegrationUrl: "about:blank" });
        handlePromiseError(rejection);
    });
});

const fetchOAuthInformation = (provider, code) => ((dispatch, getState) => {
    const state = getState();
    dispatch({ type: actions.FETCHING_OAUTH_INFORMATION, oAuthIntegrationLoading: true });
    data.get(`v2/api/payments/providers/${provider}/oauth/callback?code=${code}`).then((response) => {
        dispatch({ type: actions.FETCHED_OAUTH_INFORMATION, oAuthIntegrationLoading: false, oAuthInformation: response.data });
    }).catch((rejection) => {
        dispatch({ type: actions.FETCH_OAUTH_INFORMATION_FAILED, oAuthIntegrationLoading: false, oAuthInformation: rejection });
        handlePromiseError(rejection);
    });
});

export const dispatchToProps = (dispatch) => ({
    fetchPaymentMethods: (companyId) => { dispatch(fetchPaymentMethods(companyId)); },
    updatePaymentMethod: (companyId, activePaymentMethod) => { dispatch(updatePaymentMethod(companyId, activePaymentMethod)); },
    createPaymentMethod: (companyId, activePaymentMethod) => { dispatch(createPaymentMethod(companyId, activePaymentMethod)); },
    deletePaymentMethod: (companyId, merchantAccountId) => { dispatch(deletePaymentMethod(companyId, merchantAccountId)); },
    beginOAuthIntegration: (provider) => { dispatch(beginOAuthIntegration(provider)); },
    fetchOAuthInformation: (provider, code) => { dispatch(fetchOAuthInformation(provider, code)); }
});