import React, { Component } from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

import "../css/header.scss";
import Resources from "../lib/resources";
import NavItem from "./navItem";
import Home from "./home";

import { dispatchToProps as uaDP } from "../store/user-actions";
const dispatchToProps = (dispatch) => ({
  ...uaDP(dispatch),
});

class App extends Component {
  render() {
    return (
      <Row id="app-container" noGutters>
        <Col id="app-nav" sm={12} md={3} lg={2} xl={2}>
          <img id="app-logo" src="/img/lockstep.png" title={Resources.Lockstep} alt={Resources.Lockstep} />
          <NavItem to="/health" icon="fas fa-clinic-medical" resource={Resources.HealthDashboard} />
          <NavItem to="/invite" icon="far fa-id-badge" resource={Resources.SignupControlPanel} />
          <NavItem to="/history" icon="fas fa-history" resource={Resources.InvitationHistory} />
          <NavItem to="/users" icon="fas fa-users" resource={Resources.ManageUsers} />
          <NavItem to="/stats" icon="fas fa-chart-pie" resource={Resources.Statistics} />
          <NavItem to="/features" icon="fas fa-bug" resource={Resources.FeatureGroups} />
          <NavItem to="/bulksenders" icon="fas fa-list" resource={Resources.BulkSenders} />
          <NavItem to="/templates" icon="fas fa-envelope-open-text" resource={Resources.ServiceEmailTemplates} />
          <NavItem to="/connectors" icon="fas fa-wrench" resource={Resources.ConnectorManagement} />
          <NavItem to="/paymentMethods" icon="fas fa-dollar-sign" resource={Resources.PaymentMethods} />
          <NavItem to="/internationalization" icon="fas fa-globe" resource={Resources.Internationalization} />
          <NavItem to="/unprocessedentities" icon="fas fa-bath" resource={Resources.UnprocessedEntities} />
          <NavItem to="/migration" icon="fas fa-arrow-right" resource={Resources.Migration} />
          <NavItem to="/scripts" icon="fas fa-cubes" resource={Resources.ScriptManagement} />
        </Col>
        <Col sm={12} md={9} lg={10} xl={10} id="app-content">
          <a id="app-header-logout-btn" onClick={this.props.logout}>
            <span className="fas fa-power-off" />
          </a>
          <Route path="/:view?" component={Home} />
        </Col>
      </Row>
    );
  }
}

const storeToProps = (store) => {
  return {
    user: store.user,
  };
};

export default connect(storeToProps, dispatchToProps)(App);
